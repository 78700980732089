import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Hero from "../../components/Hero/Hero"
import SEO, { seoPropsMapping } from "../../components/seo"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../../components/VindiciaComponents"

const OneColumn = ({ data }) => {
  const section = data.primary
  return (
    <section id={section.section_id} className={`uk-section uk-padding-remove-bottom`}>
      <div className="uk-container">
        {section.copy && (
          <div
            className={`${
              data.slice_label.includes("one-column-center") ? "uk-text-center" : "uk-text-left"
            }`}
            dangerouslySetInnerHTML={{ __html: section.copy.html }}
          />
        )}
      </div>
    </section>
  )
}

const ProfileCards = ({ data }) => {
  const section = data.primary
  const items = data.items

  return (
    <section
      id={section.section_id}
      className={`uk-section uk-section-xsmall uk-margin-large-bottom`}
    >
      <div className="uk-container">
        <div className={`uk-grid-margin-medium uk-grid-match uk-child-width-1-4@m`} data-uk-grid>
          {items.map((item, i) => (
            <div key={`profile-${i}`} style={{ cursor: "pointer" }}>
              <div className="uk-inline uk-position-relative">
                <Image
                  src={item.card_header_image.fluid.src}
                  srcSet={item.card_header_image.fluid.srcSet}
                  alt={item.name}
                  style={{
                    width: "100%",
                  }}
                />
                <div
                  className="uk-overlay uk-overlay-primary-light uk-position-cover"
                  data-uk-toggle={`target: #profile-${i}`}
                />
                <div className="uk-position-bottom-left uk-position-small uk-margin-bottom">
                  <div className="uk-grid-collapse uk-flex-bottom" data-uk-grid>
                    <div
                      className="uk-width-expand@m uk-light"
                      data-uk-toggle={`target: #profile-${i}`}
                    >
                      <h3 className="uk-margin-remove-vertical">{item.name}</h3>
                      <p className="uk-margin-remove-vertical">{item.role}</p>
                    </div>
                    <div className="uk-text-right sc-only">
                      {item.linkedin && item.linkedin.url && (
                        <a
                          href={item.linkedin.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="uk-icon-button uk-margin-small-left"
                          data-uk-icon="icon: linkedin; ratio: 0.8"
                        >
                          <i className="uk-icon-linkedin-square"></i>
                        </a>
                      )}
                      {item.twitter && item.twitter.url && (
                        <a
                          href={item.twitter.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="uk-icon-button uk-margin-small-left"
                          data-uk-icon="icon: twitter; ratio: 0.8"
                        >
                          <i className="uk-icon-twitter-square"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div id={`profile-${i}`} className={`uk-flex-top uk-modal-container`} data-uk-modal>
                <div
                  style={{ background: "#333" }}
                  className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-light"
                >
                  <button className="uk-modal-close-default" type="button" data-uk-close></button>
                  <div
                    className="uk-grid uk-grid-medium uk-child-width-1-2@m uk-padding-remove"
                    data-uk-grid
                  >
                    <div>
                      <div
                        className="uk-margin-remove uk-padding-small"
                        style={{ backgroundColor: "#262626" }}
                      >
                        <Image
                          src={item.profile_photo.fluid.src}
                          srcSet={item.profile_photo.fluid.srcSet}
                          alt={item.name}
                          style={{
                            maxHeight: "400px",
                            maxWidth: "280px",
                          }}
                        />
                        <p className="uk-h2 uk-margin-remove-vertical">{item.name}</p>
                        <p className="uk-h6 uk-margin-remove-vertical">{item.role}</p>
                      </div>
                    </div>
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: item.card_body_content.html }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const Team = ({ data, location }) => {
  const section = data.prismicOurTeamPage.data

  const contentType = section => {
    let t
    const type = section.slice_type

    switch (type) {
      case "1-column_container-small":
        t = <OneColumn key={`section-${section.primary.section_id}`} data={section} />
        break
      case "3-column-cards":
        t = <ProfileCards key={`section-${section.primary.section_id}`} data={section} />
        break
      default:
        break
    }
    return t
  }

  const seoProps = seoPropsMapping(section)

  return (
    <Layout>
      <SEO {...seoProps} />
      <Hero data={section} path={location.pathname} />
      {section.body.map((section, i) => contentType(section))}
    </Layout>
  )
}

export default withPreview(Team)

export const teamQuery = graphql`
  {
    prismicOurTeamPage {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        page_title
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        hero_title {
          html
          text
        }
        body {
          ... on PrismicOurTeamPageBody1ColumnContainerSmall {
            slice_type
            slice_label
            primary {
              section_id
              section_name
              copy {
                html
              }
            }
          }
          ... on PrismicOurTeamPageBody3ColumnCards {
            slice_type
            primary {
              section_id
              section_name
            }
            items {
              name
              role
              card_header_image {
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              profile_photo {
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              card_body_content {
                html
              }
              linkedin {
                target
                url
              }
              twitter {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`
